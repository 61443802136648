//选择人员
<template>
  <div>
    <!-- 增加人员  -->
      <div class="addBtn">
      <div class="addButton" @click="showSelect">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      class="main-page-table"
      :columns="selectPersonnelTable"
      :showPage="false"
      :tableAttrs="{
        data: contractData,
        stripe: true
      }"
      ref="tableData"
      :webPage="false"
      :isCaculateHeight="false"
    >
      <template slot="radio" slot-scope="scope">
        <div class="radio">
          <el-radio
            v-model="scope.row.chose"
            @change="change(scope.$index)"
            :label="true"
            >&nbsp;</el-radio
          >
        </div>
      </template>
      <!-- <template slot="action" slot-scope="scope">
        <icon-button
          content="删除"
          icon="iconfont iconshanchu1"
          @click.prevent="del(scope.$index)"
        ></icon-button>
      </template> -->
    </base-table>
  </div>
</template>
<script>
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { selectPersonnelConfig } from '../utils/make-config'
export default {
  components: { BaseTable },
  data () {
    return {
      contractData: []
    }
  },
  computed: {
    selectPersonnelTable () {
      return selectPersonnelConfig(this)
    }
  },
  methods: {
    showSelect () {

    },
    change () {

    }
  }
}
</script>
<style lang="scss" scoped>
.addBtn {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding-right: 19px;
  padding-top: 10px;
  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
